<!--
 *                                                     __----~~~~~~~~~~~------___
 *                                    .  .   ~~//====......          __--~ ~~
 *                    -.            \_|//     |||\\  ~~~~~~::::... /~
 *                 ___-==_       _-~o~  \/    |||  \\            _/~~-
 *         __---~~~.==~||\=_    -_--~/_-~|-   |\\   \\        _/~
 *     _-~~     .=~    |  \\-_    '-~7  /-   /  ||    \      /
 *   .~       .~       |   \\ -_    /  /-   /   ||      \   /
 *  /  ____  /         |     \\ ~-_/  /|- _/   .||       \ /
 *  |~~    ~~|--~~~~--_ \     ~==-/   | \~--===~~        .\
 *           '         ~-|      /|    |-~\~~       __--~~
 *                       |-~~-_/ |    |   ~\_   _-~            /\
 *                            /  \     \__   \/~                \__
 *                        _--~ _/ | .-~~____--~-/                  ~~==.
 *                       ((->/~   '.|||' -_|    ~~-/ ,              . _||
 *                                  -_     ~\      ~~---l__i__i__i--~~_/
 *                                  _-~-__   ~)  \--______________--~~
 *                                //.-~~~-~_--~- |-------~~~~~~~~
 *                                       //.-~~~--\
 *                       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *                               神兽保佑            永无BUG
 *
 * @Author: lijing
 * @Date: 2023-08-02 14:34:02
 * @LastEditors: lijing
 * @LastEditTime: 2023-08-29 15:43:46
 * @Description:
 -->

<template>
  <div class="gaeRecord">
      <!-- 同步 -->
      <el-dialog title="停车场名称" :visible.sync="dialogTableVisible" width="1450px" class="EocConnectDialog" :close-on-click-modal="false" @close="consoleDetail">
        <!-- 子车场 -->
        <div class="parkInfo" v-if="slaveRelation == 2">
          <div class="item">车场编号：<span>{{ parkCode || '-' }}</span></div>
          <div class="item">新增泊位: <span class="textred">{{ infomation.addBerth || '-' }}</span>条</div>
          <div class="item">
            修改泊位：
            <span class="textred">{{ infomation.updateBerth || '-' }}</span>条
            (涉及设备下线<span class="textred">{{ infomation.offLineEquipment || '-' }}</span>条)
          </div>
          <div class="item">设备品牌：<span>{{ '爱泊车' }}</span></div>
          <div class="item">关联父车场：<span>{{ infomation.parentParkName || '-' }}</span></div>
          <div class="item">区域：<span>{{ infomation.areaName || '-' }}</span></div>
          <div class="item">商户：<span>{{ infomation.operationName || '-' }}</span></div>
          <div class="item">车场设备类型：<span>{{ infomation.parkEquipmentTypeName || '-' }}</span></div>
        </div>
        <!-- 父车场 -->
        <div class="parkInfo parkInfoA" v-if="slaveRelation == 1">
          <div class="item">车场编号：<span>{{ parkCode || '-' }}</span></div>
          <div class="item">新增车场: <span class="textred">{{ infomation.addPark || '-' }}</span>条</div>
          <div class="item">修改车场：<span class="textred">{{ infomation.updatePark || '-' }}</span>条</div>
          <div class="item">设备品牌：<span>{{ '爱泊车' }}</span></div>
        </div>
        <!-- 独立车场 -->
        <div class="parkInfo parkInfoA" v-if="slaveRelation == 0">
          <div class="item">车场编号：<span>{{ parkCode || '-' }}</span></div>
          <div class="item">新增泊位: <span class="textred">{{ infomation.addBerth || '-' }}</span>条</div>
          <div class="item">
            修改泊位：
            <span class="textred">{{ infomation.updateBerth || '-' }}</span>条
            (涉及设备下线<span class="textred">{{ infomation.offLineEquipment || '-' }}</span>条)
          </div>
          <div class="item">设备品牌：<span>{{ '爱泊车' }}</span></div>
        </div>

        <div class="displayFlex dialogHeight">
          <div class="titleName">
            总体：
          </div>
          <div class="flex dialogLeft">
            <!-- 子车场/独立车场 -->
            <el-table :data="infoData" v-if="slaveRelation != 1" max-height="350">
                <el-table-column prop="berthCode" :label="$t('searchModule.Berth_number')" align="center"></el-table-column>
                <el-table-column prop="syncTypeName" label="更新类型" align="center" width="100"></el-table-column>
                <el-table-column prop="barCode" :label="$t('searchModule.Pole_position_number')" align="center"></el-table-column>
                <el-table-column prop="equipmentCode" label="设备编码" align="center"></el-table-column>
                <el-table-column prop="equipmentTypeName" :label="$t('searchModule.Equipment_type')" align="center"></el-table-column>
                <el-table-column prop="parkCarCount" label="有无车辆" align="center" width="100" :formatter="parkCarCount"></el-table-column>
                <el-table-column prop="beforeUpdate" label="ACB修改前" align="center" :formatter="beforeUpdate"></el-table-column>
                <el-table-column prop="afterUpdate" label="AIpha修改后" align="center" :formatter="afterUpdate"></el-table-column>
            </el-table>
            <!-- 父车场 -->
            <el-table :data="infoData" v-if="slaveRelation == 1" max-height="350">
                <el-table-column prop="parkCode" :label="$t('searchModule.parking_code')" align="center"></el-table-column>
                <el-table-column prop="parkName" :label="$t('searchModule.park_name')" align="center"></el-table-column>
                <el-table-column prop="equipmentTypeName" label="车场设备类型" align="center"></el-table-column>
                <el-table-column prop="beforeUpdate" label="ACB修改前" align="center"></el-table-column>
                <el-table-column prop="afterUpdate" label="AIpha修改后" align="center"></el-table-column>
            </el-table>
          </div>
          <div v-if="slaveRelation != 1">
            <div class="titleName">
              关联设备变更：
            </div>
            <div class="flex dialogRight">
              <el-table :data="infoDataAfter" max-height="200">
                  <el-table-column prop="equipmentCode" label="设备编码" align="center"></el-table-column>
                  <el-table-column prop="equipmentTypeName" :label="$t('searchModule.Equipment_type')" align="center"></el-table-column>
                  <el-table-column prop="syncTypeName" label="更新类型" align="center"></el-table-column>
                <el-table-column prop="berthCode" label="泊位编码" align="center"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
          <div class="titleName">
            完善业务信息：
          </div>

        <div>
            <el-form
                ref="searchWrapper"
                :inline="true"
                label-position="right"
                :model="formInline"
                class="demo-form-inline"
                :rules="rules"
            >
              <el-form-item :label="$t('searchModule.region')" prop="areaId" v-if="slaveRelation != 2" hide-required-asterisk>
                <a-cascader ref="cascader" @change="handleAreaChange" v-if="infomation.acsExit != 1"></a-cascader>
                <el-input v-else :disabled="infomation.acsExit == 1" v-model="infomation.areaName"></el-input>
              </el-form-item>

              <el-form-item :label="$t('searchModule.Merchant')" prop="operationId" v-if="slaveRelation != 2">
                <el-select
                  style="width: 245px;"
                  v-model.trim="formInline.operationId"
                  filterable
                  :disabled="infomation.acsExit == 1"
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="经纬度" prop="parkAddress">
                <el-input :disabled="infomation.acsExit == 1" class="lnglat" type="text" style="width: 475px;" v-model="formInline.parkAddress" placeholder="在地图上选择坐标点"></el-input>
                <div class="ing-icon" @click="openMap" v-if="infomation.acsExit != 1">
                  <img src="~@/assets/img/coordinate-icon.png" alt="" />
                </div>
              </el-form-item>


              <el-form-item
                label="停车场设备类型"
                prop="parkEquipmentType"
                v-if="slaveRelation != 2"
              >
                <el-select
                 style="width: 125px;"
                  v-model="formInline.parkEquipmentType"
                  :disabled="infomation.acsExit == 1"
                  placeholder="请选择停车场设备类型"
                >
                  <el-option
                    v-if="v.code!=4"
                    :label="v.desc"
                    :value="v.code"
                    :key="v.code"
                    v-for="v in parkEquipmentType"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
        </div>


        <div class="titleName labelVerify">
          备注说明：
        </div>
        <div class="check">
          <el-input type="textarea" v-model="formInline.description" placeholder="请同步说明"></el-input>
        </div>


        <div class="btn">
          <el-button type="primary" @click="submit(1)">确认同步</el-button>
          <el-button type="primary" @click="submit(0)">驳回</el-button>
          <el-button @click="consoleDetail">关闭</el-button>
        </div>
      </el-dialog>

      <map-con
        ref="mapConpent"
        :city="city"
        @mapCancel="mapCancel"
        @mapSure="mapSure"
      ></map-con>
  </div>
</template>
<script>

import mapCon from "@/components/map";
export default {
  components: {
    mapCon
  },
  data() {
    return {
      parkCode: '',
      slaveRelation: '',
      city: "",
      dialogTableVisible: false,
      infoData: [],
      infoDataAfter: [],
      tenantList: [],
      parkEquipmentType: [],
      infomation: [],
      checkForm: {
        desc: ''
      },

      rules: {
        areaId: [
          {
            required: true,
            message: "请选择区域",
            trigger: "blur"
          }
        ],
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur"
          }
        ],
        parkAddress: [
          {
            required: true,
            message: "请选择经纬度",
            trigger: "change"
          }
        ],
        parkEquipmentType: [
          {
            required: true,
            message: "请选择停车场设备类型",
            trigger: "blur"
          }
        ],
      },
      formInline: {
        areaId: '',
        parkEquipmentType: 2,
        operationId: '',
        lnglat: '',
        longitude: "",
        latitude: "",
        parkAddress: "",
        description: ""
      }
    }
  },
  computed: {
    // 有无车辆
    parkCarCount() {
      return (val) => {
        switch (val.parkCarCount) {
          case 0:
            return '无车'
          default:
            return '有车'
        }
      };
    },
    // ACB修改前
    beforeUpdate() {
      return (val) => {
        if (val.beforeUpdate) return val.beforeUpdate;
        return '-';
      }
    },
    // AIpha修改前
    afterUpdate() {
      return (val) => {
        if (val.afterUpdate) return val.afterUpdate;
        return '-';
      }
    }
  },
  methods: {
    // 开启
    open(parkCode) {
      this.dialogTableVisible = true;
      this.getsyncAlphaPark(parkCode);
    },
    // 同步弹窗关闭
    consoleDetail() {
      this.formInline = {
        areaId: '',
        parkEquipmentType: 2,
        operationId: '',
        lnglat: '',
        longitude: "",
        latitude: "",
        parkAddress: "",
        description: ""
      }
      this.$refs.searchWrapper.clearValidate();
      this.dialogTableVisible = false;
    },
    // 打开地图
    openMap() {
      // let city = this.treeData[0].areaName;
      this.$refs.mapConpent.openMap(this.formInline.lnglat);
    },
    // 地图取消
    mapCancel() {
      this.$refs.mapConpent.mapClose();
    },
    // 地图确认
    mapSure(opt) {
      this.formInline.latitude = Math.round(opt.temLat * 1000000);
      this.formInline.longitude = Math.round(opt.temLng * 1000000);
      this.formInline.parkAddress = opt.parkAddress;
      this.formInline.lnglat = opt.hasSelect;
      this.$refs.mapConpent.mapClose();
    },

    // 选择区域
     handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.formInline.areaId = areaId;
    },

    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取同步数据
    getsyncAlphaPark(parkCode) {
      this.$axios
      .get("/acb/2.0/syncAlphaPark/syncDetail", {
        data: {
          parkCode: parkCode
        },
      })
      .then((res) => {
        if (res.state == 0) {
          let { childParkList, slaveRelation, parkCode, berthList, equipmentDetailList, acsExit } = res.value
          let arrData = res.value;
          if (childParkList || berthList) {
            this.infoData = childParkList || berthList;
          }
          if (equipmentDetailList) {
            this.infoDataAfter = equipmentDetailList;
          }
          if (acsExit == 1) {
            let { areaId, operationId, longitude, latitude, parkAddress, parkEquipmentType } = arrData;
            this.formInline = { ...this.formInline, areaId, operationId, longitude, latitude, parkAddress, parkEquipmentType };
            console.log(this.formInline)
          }
          this.slaveRelation = slaveRelation;
          this.parkCode = parkCode;
          this.infomation = res.value;
        } else {
          this.$alert(res.desc);
        }
      });
    },
    // 获取不同下拉数据
    queryTypeList() {
      let url = "/acb/2.0/park/getAddOption";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.parkEquipmentType = data["parkEquipmentType"];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },

    submit(num) {
      // 校验判断
      this.$refs["searchWrapper"].validate(valid => {
        if (valid) {
          if (this.formInline.description) {
            this.getAddOption(num)
          } else {
            this.$alert('备注说明不能为空', this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        } else {
          return false;
        }
      });
    },
    // 同步提交请求
    getAddOption(num) {
      let url = "/acb/2.0/syncAlphaPark/syncPark";
      this.$axios.post(url, {
        data: {
          parkCode: this.parkCode,
          areaId: this.formInline.areaId,
          operationId: this.formInline.operationId,
          latitude: this.formInline.latitude,
          longitude: this.formInline.longitude,
          parkAddress: this.formInline.parkAddress,
          parkEquipmentType: this.formInline.parkEquipmentType,
          description: this.formInline.description,
          syncState: num,
        }
      }).then((res) => {
        if (res.state == 0) {
          this.consoleDetail();
          this.$emit('searchData')
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    }
  },
  mounted() {
    this.queryTypeList();
    this.getTenantList();
  }
}
</script>
<style lang="stylus" scoped>
.labelVerify:before {
  content: '*';
  color: red;
}

.EocConnectDialog {
  .btn {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }

  .textred {
    color: red;
  }

  .ing-icon {
    position: absolute;
    right: 6px;
    top: 3px;

      img {
        width: 100%;
      }
  }

  .titleName {
    font-weight: 800;
    color: #3F9EFF;
    margin-bottom: 10px;
    margin-top: 10px;
    span {
      color: #000;
    }
  }

  .parkInfo {
    height: 115px;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    padding: 20px;
    .item {
      font-weight: 800;
      width: 25%;
      span {
        display: inline-block;
        font-weight: 500;
      }
    }
  }
  .parkInfoA {
    height: 0;
    padding: 20px 0;
  }
}


.gaeRecord /deep/ .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}
</style>
